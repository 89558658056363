import { Button, Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronRight } from "@material-ui/icons";
import * as React from "react";
import { Fragment, useState } from "react";
import { Trans } from "react-i18next";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import CookiePolicyContent from "./CookiePolicyContent";

const cookieBanner = require("../../assets/images/customer/cookiebanner.png");

const useStyles = makeStyles({
  container: {
    width: "100vw",
    padding: "0px",
    marginTop: "0px",
    backgroundColor: "#FFF",
    position: "relative",
  },
  header: {
    margin: "0px",
    width: "100vw",
    position: "relative",
    backgroundImage: "url(" + cookieBanner + ")",
    color: "white",
    textAlign: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  headerTitle: {
    color: "white",
    fontSize: "52px",
    lineHeight: "62px",
    fontWeight: 700,
  },
  paragraph: {
    padding: "0px 50px 0px 50px",
    maxWidth: "90%",
    margin: "0 auto 20px",
    fontFamily: "sans-serif",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 400,
  },
  buttons: {
    marginRight: "13px",
    backgroundColor: "#4a851f",
    marginBottom: "2rem",
    fontSize: "16px",
    fontWeight: 700,
    height: "40px",
    lineHeight: "40px",
    borderRadius: "4px",
    "& .MuiButton-label": {
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    "&:hover": {
      backgroundColor: "#025c1a",
    },
    color: "white",
    textTransform: "none",
  },
  policy: {
    position: "relative",
    top: "50%",
    left: "50%",
    outline: 0,
    marginRight: "50%",
    transform: "translate(-50%)",
    maxHeight: "85%",
    width: "75%",
    overflow: "auto",
    padding: ".25rem",
  },
  dismiss: {
    color: "white",
    marginTop: ".5%",
    marginRight: ".5%",
    marginLeft: "auto",
    fontFamily: "sans-serif",
    fontSize: "20px",
    float: "right",
    display: "block",
    textDecoration: "none",
    border: "2px solid #fff",
    borderRadius: "20%",
    padding: "4px",
    WebkitBoxShadow: "4px 4px 4px #404040",
    boxShadow: "4px 4px 4px #404040",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#091B4D",
    },
  },
});

const CookiePolicyPage = (props): JSX.Element => {
  const classes = useStyles();

  const [ redirect, setRedirect ] = useState<boolean>(false);

  const redirectToCookiePreferences = () => {
    setRedirect(true);
  };

  return (
    <Fragment>
      <Card className={classes.header}>
        <CardContent>
        <Link to={props.destinationStr} className={classes.dismiss} aria-label="dismiss">X</Link>
          <h1 className={classes.headerTitle}>
            <Trans i18nKey={"cookie_policy_header"} />
          </h1>
          <p className={classes.paragraph} style={{ maxWidth: "50%" }}>
            <Trans i18nKey={"changeCookieConsent"} />
          </p>
          <Button
            id="acceptCookiesButton"
            variant="contained"
            className={classes.buttons}
            endIcon={<ChevronRight />}
            onClick={redirectToCookiePreferences}
          >
            <Trans i18nKey={"cookiePreferenceTool"} />
          </Button>
        </CardContent>
      </Card>
      <Card className={classes.container}>
        <div className={classes.policy}>
          <CookiePolicyContent />
        </div>
      </Card>
      {redirect ? (<Redirect to="/cookiepreferences" />) : null}
    </Fragment>
  );
};

export default CookiePolicyPage;
