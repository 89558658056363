import { Button, createStyles, Typography, withStyles, WithStyles } from "@material-ui/core";
import { AddOutlined } from "@material-ui/icons";
import * as _ from "lodash";
import * as React from "react";
import { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import ARDocumentUpload from "./ARDocumentUpload";

interface Props extends WithStyles {
    id: string;
    label: string;
    error: boolean;
    fieldValue: DocumentRecord[];
    extras: Extras;
}

interface State {
    extraDocs: number;
}

interface Extras {
    supportingDocumentsIndex: number,
    reviewMode?: boolean,
}

export interface DocumentRecord {
    documentId: number,
    description: string,
}

const style = (theme) => createStyles({
    buttonBorder: {
        backgroundColor: "#EEEEEE",
        borderWidth: "1px",
        borderColor: "#868E96",
        borderRadius: 5,
    },
    dashedBorder: {
        borderStyle: "dashed",
    },
    iconMargin: {
        marginRight: "5px",
    },
    plusIcon: {
        color: "#868E96",
    },
    uploadContainer: {
        position: "relative",
        margin: "5px",
    },
});

class ARDocumentUploadContainer extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            extraDocs: this.props.fieldValue.length > 3 ? this.props.fieldValue.length - 3 : 0,
        };

        this.onChange = this.onChange.bind(this);
    }

    public componentDidUpdate(prevProps) {
        if (this.props.fieldValue.length !== prevProps.fieldValue.length) {
            const extraDocs = this.props.fieldValue.length > 3 ? this.props.fieldValue.length - 3 : 0;
            this.setState({ extraDocs });
        }
    }

    // TODO: Change this to have a customizable number of uploads and optional additional uploads. Replace old uploads in forms.
    public render() {
        const { classes, extras, label, fieldValue, id } = this.props;

        const buttonIcon: JSX.Element = (
            <AddOutlined className={[classes.iconMargin, classes.plusIcon].join(" ")}/>
        );

        const extraDocUpload: JSX.Element[] = [];
        const review = !!(extras && extras.reviewMode);
        // TODO: rename extraDocs to something better.
        _.times(this.state.extraDocs, (i) => {
            extraDocUpload.push(
                <ARDocumentUpload
                    id={"id:" + i}
                    label={label}
                    document={fieldValue[i + 3]}
                    reviewMode={review}
                />,
            );
        });
        return (
            <Fragment>
                <ARDocumentUpload
                    id={"one"}
                    key={"one"}
                    label={label}
                    document={fieldValue[0]}
                    reviewMode={review}
                />
                <ARDocumentUpload
                    id={"two"}
                    key={"two"}
                    label={label}
                    document={fieldValue[1]}
                    reviewMode={review}
                />
                <ARDocumentUpload
                    id={"three"}
                    key={"three"}
                    label={label}
                    document={fieldValue[2]}
                    reviewMode={review}
                />
                {extraDocUpload}
                <Button
                    id={id + ": button"}
                    variant="outlined"
                    onClick={this.onChange}
                    disabled={this.shouldDisableButton()}>
                    {buttonIcon}
                    <Typography>
                        <Trans>{"add_another_file"}</Trans>
                    </Typography>
                </Button>
            </Fragment>
        );
    }

    private onChange(): void {
        this.setState({extraDocs: this.state.extraDocs + 1});
    }

    private shouldDisableButton(): boolean {
        return this.state.extraDocs + 3 > this.props.fieldValue.length;
    }
}

export default withStyles(style)(ARDocumentUploadContainer);
