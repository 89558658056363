import { Grid } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import * as React from "react";
import convertImages from "../../services/ImageCoverter";

const elavonLogo = convertImages(require("../../assets/images/ElavonLogo.png"));

export interface Props {
    appError?: Error;
    errorScreen: JSX.Element;
}

interface State {
    doesChildHaveRenderError: boolean;
}

const styles = (theme) => createStyles({
    logoContainer: {
        display: "inline-block",
        marginLeft: "100px",
        marginTop: "80px",
    },
    logo: {
        height: "75px",
        display: "inline-block",
    },
    errorContainer: {
        margin: "0 auto",
        minHeight: "1vh",
    },
});

class ErrorBoundary extends React.Component<Props & WithStyles, State> {
    constructor(props: Props & WithStyles) {
        super(props);

        this.state = { doesChildHaveRenderError: false };

        this.handleError = this.handleError.bind(this);
        this.hasError = this.hasError.bind(this);
    }

    public componentDidCatch(error: Error) {
        this.handleError(error);
    }

    public render() {
        return this.hasError() ? this.renderErrorScreen() : this.props.children;
    }

    public renderErrorScreen() {
        const { classes, errorScreen } = this.props;

        return (
            <React.Fragment>
                <Grid container={true}>
                    <Grid item={true} xs={12}>
                        <div className={classes.logoContainer}>
                            <img src={elavonLogo} alt="Elavon" className={classes.logo} />
                        </div>
                    </Grid>
                </Grid>
                <Grid
                    container={true}
                    className={classes.errorContainer}
                    direction="row"
                    alignItems="center"
                    justify="center"
                    wrap="wrap-reverse">
                    {errorScreen}
                </Grid>
            </React.Fragment>
        );
    }

    private handleError(error: Error) {
        if (error) {
            this.setState({ doesChildHaveRenderError: true });
        }
    }

    private hasError() {
        return this.state.doesChildHaveRenderError || !!this.props.appError;
    }
}

export default withStyles(styles)(ErrorBoundary);
