import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import ARHttp, { ScarecrowResponse } from "./ARHttp";
import { getStore } from "./StoreService";

export interface VerifyIDState {
    elements: JSX.Element[];
}

interface GetVerificationQuizRequest {
    email?: string;
}

export interface QuizQuestionChoice {
    answerNumber: number;
    answerText: string;
}

export interface QuizQuestion {
    questionNumber: number;
    questionText: string;
    questionChoices: QuizQuestionChoice[];
}

export interface GetQuizResponse extends ScarecrowResponse {
    transactionKey: string;
    quizId: number;
    quizQuestions: QuizQuestion[];
}

export interface QuizAnswer {
    questionNumber: number;
    answerNumber: number;
}

export interface SetVerificationQuizRequest {
    quizId: number;
    country: string;
    quizAnswers: QuizAnswer[];
}

export interface CustomerSetVerificationQuizRequest {
    id: string;
    quizId: number;
    country: string;
    quizAnswers: QuizAnswer[];
}

export function GetVerificationQuiz(): Observable<GetQuizResponse> {
    const createAppBody: GetVerificationQuizRequest = {};

    const quizObservable = ARHttp({
        method: "POST",
        url: "/sb/apps/getquiz",
        body: createAppBody,
    }).pipe(
        map((response) => {
            return response as GetQuizResponse;
        }),
        catchError((error) => {
            getStore().dispatch({ type: "SET_ERROR", payload: error });
            return of(error);
        }),
    );
    return quizObservable;
}

export function getCustomerVerificationQuiz(appId: string, ac: string, br: string, ctoken: string, isThirdParty):
Observable<GetQuizResponse> {
        if (isThirdParty === true) {
            return ARHttp({
                method: "POST",
                url: `/scwebapi/apps/getthirdpartyquiz?ac=${ac}&br=${br}&ctoken=${ctoken}`,
                body: {
                    id: appId,
                },
            }).pipe(
                map((response) => {
                    return response as GetQuizResponse;
                }),
            );
        } else  {
            return ARHttp({
                method: "POST",
                url: `/scwebapi/apps/getquiz?ac=${ac}&br=${br}&ctoken=${ctoken}`,
                body: {
                    id: appId,
                },
            }).pipe(
                map((response) => {
                    return response as GetQuizResponse;
                }),
            );
        }
}

export function customerAnswerVerificationQuiz(
    appId: string,
    country: string,
    brand: string,
    quizId: number,
    quizAnswers: QuizAnswer[],
    ctoken: string,
    isThirdPartyApp,
) {
    let thirdPartyAnswers =
        ARHttp({
            method: "POST",
            url: `/scwebapi/apps/answerquiz?ac=${country}&br=${brand}&ctoken=${ctoken}`,
            body: {
                id: appId,
                quizId,
                quizAnswers,
                country,
            },
        });
    if (isThirdPartyApp === true ) {
        thirdPartyAnswers =
        ARHttp({
            method: "POST",
            url: `/scwebapi/apps/answerthirdpartyquiz?ac=${country}&br=${brand}&ctoken=${ctoken}`,
            body: {
                id: appId,
                quizId,
                quizAnswers,
                country,
            },
        });
    }
    return thirdPartyAnswers.pipe(
        catchError((error) => {
            getStore().dispatch({ type: "SET_ERROR", payload: error });
            return of(error);
        }),
    );
}

export function AnswerVerificationQuiz(quizId: number, quizAnswers: QuizAnswer[]) {
    const createAppBody: SetVerificationQuizRequest = {
        quizId,
        quizAnswers,
        country: "USA",
    };

    return ARHttp({
        method: "POST",
        url: "/sb/apps/answerquiz",
        body: createAppBody,
    }).pipe(
        catchError((error) => {
            getStore().dispatch({ type: "SET_ERROR", payload: error });
            return of(error);
        }),
    );
}
