import { Button, Grid } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import i18next from "i18next";
import * as _ from "lodash";
import * as React from "react";
import { Redirect } from "react-router";
import SignaturePageGeneralFailure from "../../components/signature/SignaturePageGeneralFailure";
import SignaturePageESignFailure from "../../components/signature/SignaturePageESignFailure";
import SignaturePageContainer from "../../containers/signature/SignaturePageContainer";
import App from "../../models/App";
import { DocumentDisplayType, DocumentSignatureType, DocumentType } from "../../models/DocumentType";
import GroupApp from "../../models/GroupApp";
import { TermState } from "../../models/TermState";
import ARHttp from "../../services/ARHttp";
import {
    checkLockStatus,
    shouldCheckLockStatus,
    ShouldShowLockPageResponse,
} from "../../services/LockStatusCheckService";
import { submitGroupApplication, submitStandardApplication, eSignInitiate } from "../../services/persistence/PersistenceService";
import {
    checkWaitStatus,
    shouldCheckWaitStatus,
    ShouldShowWaitPageResponse,
} from "../../services/WaitStatusCheckService";
import DocumentSubmission from "../signature/DocumentSubmission";
import SignaturePageLoading from "../signature/SignaturePageLoading";

export interface AppStatus {
    appId: number;
    active: boolean;
    complete: boolean;
    isThirdParty?: boolean;
    documents?: Document[];
    isThirdPartyStandalone?: boolean;
}

interface Document {
    signed: boolean;
    link: string;
    documentType: DocumentType;
    signType: DocumentDisplayType;
    cardinalNumber?: number;
}
interface DocumentInfo {
    documentType: DocumentType;
    cardinalNumber?: number;
}
interface State {
    fontNames: string[];
    signatureError: boolean;
    eSignInitFail: boolean;
    submitting: boolean;
    loading: boolean;
    declined: boolean;
    fontStyle?: number;
    termStates?: TermState[];
    applicationCountry?: string;
    fullName?: string;
    submitted: boolean;
    groupApp: boolean;
    apps: AppStatus[];
    currentApp: number;
    brandCode: string;
    agreementsAllSigned: DocumentInfo[];
    allSingleAppsSigned: boolean;
    waiting: boolean;
    ownershipType?: string;
}

interface Props {
    appId: number;
    app?: App;
    groupApp?: GroupApp;
    cToken: string;
    requiredSignatures: DocumentSignatureType[];
    addRequiredSignature: (signature: DocumentSignatureType) => void;
    loadAppInfo: (app) => void;
    history: any;
}

export default class CustomerFlowReviewAndSign extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            fontNames: [],
            signatureError: false,
            eSignInitFail: false,
            submitting: false,
            loading: true,
            declined: false,
            submitted: false,
            groupApp: false,
            apps: [],
            currentApp: 0,
            brandCode: "",
            agreementsAllSigned: [],
            allSingleAppsSigned: false,
            waiting: false,
            ownershipType: "",
        };

        this.onSignatureSubmit = this.onSignatureSubmit.bind(this);
        this.onSignatureError = this.onSignatureError.bind(this);
        this.onSignatureModalRender = this.onSignatureModalRender.bind(this);
        this.onSignatureDeclined = this.onSignatureDeclined.bind(this);
        this.getSettingsAndProperties = this.getSettingsAndProperties.bind(this);
        this.setSignatureStatus = this.setSignatureStatus.bind(this);
        this.incrementCurrentApp = this.incrementCurrentApp.bind(this);
        this.decrementCurrentApp = this.decrementCurrentApp.bind(this);
        this.areGroupApplicationsSigned = this.areGroupApplicationsSigned.bind(this);
        this.submitGroupApplication = this.submitGroupApplication.bind(this);
        this.signAllCheckbox = this.signAllCheckbox.bind(this);
        this.setDocuments = this.setDocuments.bind(this);
        this.onDocumentSign = this.onDocumentSign.bind(this);
        this.getSignableDocuments = this.getSignableDocuments.bind(this);
        this.updateTermState = this.updateTermState.bind(this);
        this.setTermState = this.setTermState.bind(this);
        this.setWaitStatus = this.setWaitStatus.bind(this);
        this.waitForPowerToBind = this.waitForPowerToBind.bind(this);
    }

    public onSignatureInitError(): void {
        this.setState({
            submitting: false,
            eSignInitFail: true,
        });
    }

    public componentDidMount() {
        window.document.title = i18next.t("customer_ui_application");

        const { groupApp, cToken, appId, app, loadAppInfo } = this.props;

        if (groupApp) {
            if (groupApp.thirdParty) {
                ARHttp({
                    method: "POST",
                    url: "/scwebapi/group/getthirdpartygroup",
                    body: {
                        id: groupApp.id,
                    },
                    headers: {
                        "X-XSRF-TOKEN": cToken,
                    },
                }).subscribe(
                    (response: any) => {
                        const apps = _.map(response.groupAppInfoList, function(appInfo, index) {
                            const appStatus: AppStatus = {
                                appId: appInfo.id,
                                active: Number(index) === 0, // Only the first should be active at the start.
                                complete: false,
                                isThirdParty: true,
                                isThirdPartyStandalone: !!groupApp.appInfo.thirdPartyStandalone,
                            };
                            return appStatus;
                        });
                        this.setState({ apps });
                        this.setWaitStatus(apps[0].appId, true);
                        this.getSettingsAndProperties(apps[0].appId, true);
                    },
                    () => {
                        this.onSignatureError();
                    },
                );
            } else {
                ARHttp({
                    method: "POST",
                    url: "/scwebapi/group/getgroup",
                    body: {
                        id: groupApp.id,
                    },
                    headers: {
                        "X-XSRF-TOKEN": cToken,
                    },
                }).subscribe(
                    (response: any) => {
                        const groupAppInfoList = _.filter(response.groupAppInfoList, function(groupAppInfo) {
                            return groupAppInfo.appInfo.applicationType === "APPLICATION";
                        });
                        const apps = _.map(groupAppInfoList, function(groupAppInfo, index) {
                            const appStatus: AppStatus = {
                                appId: groupAppInfo.appInfo.id,
                                active: Number(index) === 0, // Only the first should be active at the start.
                                complete: false,
                            };
                            return appStatus;
                        });
                        this.setState({ apps });
                        this.setWaitStatus(apps[0].appId, false);
                        this.getSettingsAndProperties(apps[0].appId, false);
                    },
                    () => {
                        this.onSignatureError();
                    },
                );
            }
        } else {
            this.setState({
                apps: [{ appId, active: true, complete: false, isThirdParty: !!this.props.app?.thirdParty,
                    isThirdPartyStandalone: !!this.props.app?.thirdPartyStandalone}],
                ownershipType:  this.props.app!.ownershipType,
            });
            this.setWaitStatus(appId, this.props.app ? this.props.app.thirdParty : false );
            this.getSettingsAndProperties(appId, this.props.app ? this.props.app.thirdParty : false );
        }

        if (!app && !groupApp) {
            ARHttp({
                method: "POST",
                url: "/aws/getappdetails",
                body: {
                    appId,
                },
                headers: {
                    "X-XSRF-TOKEN": cToken,
                },
            }).subscribe((response: any) => {
                loadAppInfo(response.appInfo);
            });
        }
    }

    public render() {
        const { ownershipType, currentApp, signatureError, eSignInitFail, loading, submitted, declined, apps, fontNames, fullName,
            applicationCountry, brandCode, agreementsAllSigned, allSingleAppsSigned, waiting } = this.state;
        const { addRequiredSignature, groupApp, cToken } = this.props;
        let groupAppSize: number | undefined;
        if (groupApp && groupApp.groupInfo) {
            groupAppSize = groupApp.groupInfo.groupSize;
        }

        if (signatureError) {
            return (
                <div style={{ marginTop: "16px" }}>
                    <SignaturePageGeneralFailure />
                </div>
            );
        }

        if (eSignInitFail) {
            return (
                <div style={{ marginTop: "16px" }}>
                    <SignaturePageESignFailure />
                </div>
            );
        }

        if (loading) {
            return (
                <div style={{ marginTop: "16px" }}>
                    <SignaturePageLoading />
                </div>
            );
        }

        if (submitted) {
            return <Redirect to="/submitted" />;
        }

        if (declined) {
            return <Redirect to="/applist" />;
        }

        if (waiting) {
            return <Redirect to="/updateinprogress" />;
        }
        const signaturePages = _.map(apps, (app, index) => {
            return (
                <SignaturePageContainer
                    appId={app.appId}
                    fontNames={fontNames}
                    fullName={fullName}
                    getDocumentListUrl="/aws/document/listpreboardingdocuments"
                    getDocumentUrl="/aws/document/getapplicationdocument"
                    submitting={this.state.submitting}
                    onDecline={this.onSignatureDeclined}
                    onError={this.onSignatureError}
                    onSubmit={this.onSignatureSubmit}
                    shouldShowDeclineButton={false}
                    country={applicationCountry}
                    isCustomerSignatureFlow={true}
                    addRequiredSignature={addRequiredSignature}
                    isGroupApp={!!groupApp}
                    shouldRender={app.active}
                    shouldDisplayStylePicker={index === 0}
                    setSignatureStatus={this.setSignatureStatus}
                    cToken={cToken}
                    brandCode={brandCode}
                    agreementsAllSigned={agreementsAllSigned}
                    signAllCheckbox={this.signAllCheckbox}
                    setDocuments={this.setDocuments}
                    onDocumentSign={this.onDocumentSign}
                    getSignableDocuments={this.getSignableDocuments}
                    allSingleAppsSigned={allSingleAppsSigned}
                    updateTermState={this.updateTermState}
                    setTermState={this.setTermState}
                    key={index + ""}
                    updateSpinnerState={this.state.submitting}
                    isThirdPartyApp={app.isThirdParty}
                    groupAppSize={groupAppSize}
                    optOut={applicationCountry === "POL"}
                    ownershipType={ownershipType}
                    isThirdPartyStandalone={app.isThirdPartyStandalone}
                    disableSignAll={this.waitForPowerToBind}
                />
            );
        });

        let navButtons;
        if (groupApp) {
            navButtons = (
                <Grid item={true} style={{ marginTop: "16px" }}>
                    <Button onClick={this.decrementCurrentApp} variant="contained" style={{ display: "inline-block" }}>
                        <ChevronLeft />
                    </Button>
                    <p
                        style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            display: "inline-block",
                        }}
                    >
                        {currentApp + 1} of {apps.length}
                    </p>
                    <Button onClick={this.incrementCurrentApp} variant="contained" style={{ display: "inline-block" }}>
                        <ChevronRight />
                    </Button>
                </Grid>
            );
        }

        let submission;
        if (groupApp) {
            submission = (
                <Grid item={true} style={{ marginTop: "16px" }}>
                    <DocumentSubmission
                        spinnerStatus={this.state.submitting}
                        onSubmit={this.submitGroupApplication}
                        enabled={this.areGroupApplicationsSigned()}
                        brandCode={brandCode}
                        submitting={this.state.submitting}
                        isThirdPartyStandalone={!!this.props.groupApp?.appInfo?.thirdPartyStandalone}
                        country={applicationCountry}
                    />
                </Grid>
            );
        }

        return (
            <React.Fragment>
                {navButtons}
                {signaturePages}
                {navButtons}
                {submission}
            </React.Fragment>
        );
    }

    /* istanbul ignore next */
    private waitForPowerToBind(targetDocumentType: DocumentType): boolean {
        const hasPowerToBind = !!this.state.apps.find(
            ({ documents }) => documents?.find(({ documentType }) => documentType === DocumentType.POWER_TO_BIND_RESOLUTION),
        );

        if (hasPowerToBind && targetDocumentType !== DocumentType.POWER_TO_BIND_RESOLUTION) {
            return !this.state.agreementsAllSigned.find(({ documentType }) => documentType === DocumentType.POWER_TO_BIND_RESOLUTION);
        }

        return false;
    }

    /* istanbul ignore next */
    private getSettingsAndProperties(appId: number, thirdPartyApp: any) {
        ARHttp({
            method: "POST",
            url: "/aws/document/getsettingsandproperties",
            headers: {
                "X-XSRF-TOKEN": this.props.cToken,
            },
            body: {
                appId,
                thirdPartyApp,
            },
        }).subscribe(
            (response: any) => {
                let fullName: string = "";
                fullName = fullName.concat(response.signerFirstName);
                if (response.signerMiddleName) {
                    fullName = fullName.concat(" ", response.signerMiddleName);
                }
                fullName = fullName.concat(" ", response.signerLastName);
                this.setState({
                    fontNames: response.fontNames,
                    fullName,
                    applicationCountry: response.applicationCountry,
                    loading: false,
                    brandCode: response.brandCode,
                });
            },
            () => {
                this.onSignatureError();
            },
        );
    }
    /* istanbul ignore next */
    private onSignatureModalRender(): void {
        if (shouldCheckLockStatus(this.props.app, this.props.groupApp)) {
            // @ts-ignore
            const appId: number = (this.props.groupApp) ? +this.props.groupApp.appInfo.id : +this.props.app.id;
            checkLockStatus(appId, this.props.cToken).subscribe((response: ShouldShowLockPageResponse) => {
                if (response.packetUpdated) {
                    this.setState({ submitted: false, submitting: false });
                    this.props.history.push("/updateinprogress");
                } else {
                    this.submitStandardAndGroupApplication();
                }
            }, () => this.onSignatureError());
        } else {
            this.submitStandardAndGroupApplication();
        }
    }

    private submitStandardAndGroupApplication() {
        const marketingDataConsentMap = {};
        let gdprOptInOut: boolean = false;
        _.each(this.state.termStates, (term) => {
            if (term.mapping === "gdprOptInOut") {
                if (this.state.applicationCountry === "POL") {
                    gdprOptInOut = !term.checked;
                } else {
                    gdprOptInOut = term.checked;
                }
            } else {
                marketingDataConsentMap[term.mapping] = term.checked;
            }
        });

        if (this.props.groupApp) {
            const signatures = _.uniq(this.props.requiredSignatures);
            const appIDs = _.map(this.state.apps, (app) => app.appId);
            // @ts-ignore
            submitGroupApplication(
                this.props.cToken,
                this.props.groupApp!.id,
                appIDs,
                this.state.fontNames[0],
                signatures,
                this.props.groupApp!.thirdParty,
                gdprOptInOut,
            ).subscribe(
                () => this.setState({ submitted: true, submitting: false }),
                () => this.onSignatureError(),
            );
        } else {
            // @ts-ignore
            submitStandardApplication(
                this.props.cToken,
                this.props.appId,
                this.state.fontNames[this.state.fontStyle!],
                this.props.requiredSignatures,
                marketingDataConsentMap,
                gdprOptInOut,
                this.props.app!.thirdParty,
            ).subscribe(
                () => {
                    this.setState({ submitted: true, submitting: false });
                },
                () => this.onSignatureError(),
            );
        }
    }
    /* istanbul ignore next */
    private onSignatureSubmit(style: number, termStates?: TermState[]): void {
        this.setState({submitting: true});
        eSignInitiate(
            this.props.cToken,
            this.props.appId,
        ).subscribe(
            (res) => {
                if (res.error) {
                    return this.onSignatureInitError();
                }
                this.setState({
                    fontStyle: style,
                    termStates,
                });
                this.onSignatureModalRender();
                this.setState({ submitted: true, submitting: false });
            },
            () => this.onSignatureError(),
        );
    }
    /* istanbul ignore next */
    private onSignatureError(): void {
        this.setState({
            submitting: false,
            signatureError: true,
        });
    }
    /* istanbul ignore next */
    private onSignatureDeclined(): void {
        this.setState({
            declined: true,
        });
    }
    /* istanbul ignore next */
    private setSignatureStatus(complete: boolean) {
        const apps = this.state.apps;
        apps[this.state.currentApp].complete = complete;
        let allComplete = true;
        _.forEach(apps, (app) => {
            if (app.complete === false) {
                allComplete = false;
            }
        });
        this.setState({ apps, allSingleAppsSigned: allComplete });
    }
    /* istanbul ignore next */
    private setSignatureStatusAll() {
            const apps = this.state.apps;
            _.forEach(apps, (app) => {
                if (_.filter(this.getSignableDocuments(app.appId), { signed: true }).length ===
                this.getSignableDocuments(app.appId).length && this.getSignableDocuments(app.appId).length >= 1) {
                    app.complete = true;
                } else {
                    app.complete = false;
                }
            });
            this.setState({apps});
    }
    /* istanbul ignore next */
    private incrementCurrentApp() {
        const currentApp = this.state.currentApp;
        if (this.state.apps.length - 1 !== currentApp) {
            const apps = this.state.apps;
            apps[currentApp].active = false;
            apps[currentApp + 1].active = true;
            this.setState({ apps, currentApp: currentApp + 1 });
        }
    }
    /* istanbul ignore next */
    private decrementCurrentApp() {
        const currentApp = this.state.currentApp;
        if (currentApp !== 0) {
            const apps = this.state.apps;
            apps[currentApp].active = false;
            apps[currentApp - 1].active = true;
            this.setState({ apps, currentApp: currentApp - 1 });
        }
    }
    /* istanbul ignore next */
    private areGroupApplicationsSigned() {
        return !_.find(this.state.apps, function(app: AppStatus) {
            return !app.complete;
        });
    }
    /* istanbul ignore next */
    private submitGroupApplication() {
        this.setState({ submitting: true });
        this.onSignatureModalRender();
        this.setState({ submitted: true, submitting: false });
    }
    /* istanbul ignore next */
    private signatureUpdate(apps: AppStatus[], checked: boolean, documentType: DocumentType, cardinalNumber: number) {
        _.forEach(apps, (app) => {
            _.forEach(app.documents, (document) => {
                if (document.documentType === documentType && document.cardinalNumber === cardinalNumber) {
                    document.signed = checked;
                }
            });
        });
        return apps;
    }
    /* istanbul ignore next */
    private signAllCheckbox(document: DocumentType, cardinalNumber: number) {
        const newAgreementList = this.state.agreementsAllSigned;
        let apps = this.state.apps;
        if (_.some(newAgreementList, {documentType: document, cardinalNumber})) {
            _.remove(newAgreementList, (item) => {
                return (item.documentType === document && item.cardinalNumber === cardinalNumber);
            });
            apps = this.signatureUpdate(apps, false, document, cardinalNumber);
        } else {
            newAgreementList.push({documentType: document, cardinalNumber});
            apps = this.signatureUpdate(apps, true, document, cardinalNumber);
        }
        this.setState({apps}, () => {
            this.setSignatureStatusAll();
        });
    }
    /* istanbul ignore next */
    private setDocuments(documents: Document[], appId: number) {
        const apps = this.state.apps;
        const index = apps.findIndex((item) => item.appId === appId);
        apps[index].documents = documents;
        this.setState({
            ...this.state,
            apps,
        });
    }
    /* istanbul ignore next */
    private getSignableDocuments(appId: number): Document[] {
        const apps = this.state.apps;
        const index = apps.findIndex((item) => item.appId === appId);
        return _.filter(this.state.apps[index].documents, (document: Document) => {
            return document.signType === DocumentDisplayType.SIGNABLE;
        });
    }
    /* istanbul ignore next */
    private allDocumentsSigned(app: AppStatus): boolean {
        let allSigned = true;
        if (!(_.filter(this.getSignableDocuments(app.appId), { signed: true }).length === this.getSignableDocuments(app.appId).length &&
        this.getSignableDocuments(app.appId).length >= 1)) {
            allSigned = false;
        }
        return allSigned;
    }
    /* istanbul ignore next */
    private agreementTermsChecked(): boolean {
        if (this.state.termStates) {
            let valid = true;
            _.forEach(this.state.termStates, (term) => {
                if (term.required === true && term.checked === false) {
                    valid = false;
                }
            });
            return valid;
        } else {
            return true;
        }
    }

    /* istanbul ignore next */
    private onDocumentSign(index: number, appId: number) {

        return () => {
            const documentSignedType = this.getSignableDocuments(appId)[index].documentType;
            const cardinalNumber = this.getSignableDocuments(appId)[index].cardinalNumber;

            const apps = this.state.apps;
            const spot = apps.findIndex((item) => item.appId === appId);
            _.forEach(apps[spot].documents, (document) => {
                if (document.documentType === documentSignedType && document.cardinalNumber === cardinalNumber) {
                    document.signed = true;
                }
            });
            this.setState(
                {
                    ...this.state,
                    apps,
                },
                () => {
                        this.setSignatureStatus(this.allDocumentsSigned(apps[spot]) && this.agreementTermsChecked());
                },
            );
        };
    }
    /* istanbul ignore next */
    private updateTermState(mapping: string, value: boolean) {
        this.setState({
            termStates: _.map(this.state.termStates, (term) => {
                if (term.mapping === mapping) {
                    return {
                        ...term,
                        checked: value,
                    };
                } else {
                    return term;
                }
            }),
        });
    }
    /* istanbul ignore next */
    private setTermState(termStates: TermState[]) {
        this.setState({
            ...this.state,
            termStates,
        });
    }
    /* istanbul ignore next */
    private setWaitStatus(appId: number, isThirdPartyApp: any) {
        if (shouldCheckWaitStatus(this.props.app, this.props.groupApp)) {
            checkWaitStatus(appId, isThirdPartyApp, this.props.cToken).subscribe((response: ShouldShowWaitPageResponse) => {
                if (response.showWaitPage) {
                    this.setState({ waiting: true });
                    this.props.history.push("/updateinprogress");
                }
            });
        }
    }
}
