import { Button, CardContent, CircularProgress, createStyles, Grid, Snackbar, withStyles, WithStyles } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import i18next from "i18next";
import * as _ from "lodash";
import * as React from "react";
import { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import { DocumentType } from "../../models/DocumentType";
import ApplicationDocumentService, { UploadDocument } from "../../services/ApplicationDocumentService";
import CustomerDocumentLink from "./CustomerDocumentLink";

const style = (theme) => createStyles({
    deleteButton: {
        position: "absolute",
        right: "5px",
        top: "-3px",
    },
    buttonBorder: {
        backgroundColor: "#EEEEEE",
        borderWidth: "1px",
        borderColor: "#868E96",
        borderRadius: 5,
    },
    dashedBorder: {
        borderStyle: "dashed",
    },
    iconMargin: {
        marginRight: "5px",
    },
    checkIcon: {
        color: "#07c67e",
    },
    uploadIcon: {
        color: "#868E96",
    },
    progressIcon: {
        maxHeight: "20px",
        maxWidth: "20px",
    },
    fileInput: {
        display: "none",
    },
    uploadContainer: {
        position: "relative",
    },
    error: {
        color: "#83380c",
        textAlign: "center",
        marginTop: "2px",
    },
});

interface Props extends WithStyles {
    appId: number;
    cToken: string;
    isThirdPartyApp?: boolean;
    shouldHideRemoveDocument?: boolean;
    setAppError: () => void;
}

interface State {
    error?: string;
    documents: any[];
    pending: boolean;
}

class CustomerDocumentUpload extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            documents: [],
            pending: true,
        };

        this.uploadDocuments = this.uploadDocuments.bind(this);
        this.handleUploadError = this.handleUploadError.bind(this);
        this.clearError = this.clearError.bind(this);
        this.listDocuments = this.listDocuments.bind(this);
        this.setDocuments = this.setDocuments.bind(this);
    }

    public componentDidMount() {
        this.listDocuments(this.props.appId);
    }

    public render() {
        const { classes, appId, cToken, setAppError, isThirdPartyApp, shouldHideRemoveDocument } = this.props;
        const { documents, error, pending } = this.state;

        const documentLinks: JSX.Element[] = [];
        const listDocuments = this.listDocuments;
        _.forEach(documents, function(documentLink, index) {
            documentLinks.push(
                <CustomerDocumentLink
                    key={"Document: " + index}
                    cToken={cToken}
                    appId={appId}
                    documentLink={documentLink}
                    setAppError={setAppError}
                    listDocuments={listDocuments}
                    isThirdPartyApp={isThirdPartyApp}
                    shouldHideRemoveDocument={shouldHideRemoveDocument}
                />,
            );
        });

        return (
            <Fragment>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={!!error}
                    message={error}
                    onClose={this.clearError}
                    autoHideDuration={3000}/>
                <input
                    id="CustomerDocumentUpload"
                    type="file"
                    onChange={this.uploadDocuments}
                    className={classes.fileInput}
                    />
                <div className={classes.uploadContainer}>
                    <label
                        htmlFor="CustomerDocumentUpload">
                        <Button
                            id={"CustomerDocumentUploadButton"}
                            variant="contained"
                            component="span"
                            >
                            <Trans>upload_file</Trans>
                            {pending ? <CircularProgress variant="indeterminate"
                                                         size={15}
                                                         aria-label="Circular Progress" /> : <ChevronRight />}
                        </Button>
                    </label>
                </div>
                    <CardContent>
                        <Grid container={true} direction={"row"}>
                            {documentLinks}
                        </Grid>
                    </CardContent>
            </Fragment>
        );
    }

    private uploadDocuments(event): void {
        const file: File = event.target.files[0];
        const reader: FileReader = new FileReader();

        const listDocuments = this.listDocuments;
        const handleUploadError = this.handleUploadError;
        const appId = this.props.appId;
        const cToken = this.props.cToken;
        const isThirdPartyApp = this.props.isThirdPartyApp;

        reader.addEventListener('load', () => {
            const uploadDocument: UploadDocument = {
                documentType: DocumentType.SUPPORTING_DOCUMENT,
                base64Body: (reader.result as string),
                mimeType: file.type,
                description: file.name,
            };

            ApplicationDocumentService.uploadScarecrowDocuments(cToken, appId, [uploadDocument], '', undefined, isThirdPartyApp).subscribe(
                function(res: any) {
                    listDocuments(appId);
                },
                function(error: Error) {
                    handleUploadError(error);
                },
            );
        });
        reader.readAsDataURL(file);
    }

    private handleUploadError(error: Error): void {
        if (error.message === "Unsupported Document Type") {
            // @ts-ignore
            this.setState({error: i18next.t("upload_failed_invalid_mime_type")});
        } else if (error.message === "Maximum Document Size Exceeded") {
            // @ts-ignore
            this.setState({error: i18next.t("upload_failed_file_too_big")});
        } else {
            // @ts-ignore
            this.setState({error: i18next.t("upload_failed_generic")});
        }
    }

    private clearError() {
        this.setState({ error: "" });
    }

    private listDocuments(appId: number) {
        const setAppError = this.props.setAppError;
        const setDocuments = this.setDocuments;
        const cToken = this.props.cToken;
        const isThirdPartyApp = this.props.isThirdPartyApp;

        ApplicationDocumentService.listScarecrowDocuments(cToken, appId, "", isThirdPartyApp).subscribe(
            function(res: any) {
                setDocuments(res.documentLinks);
            },
            function(error: Error) {
                setAppError();
            },
        );
    }

    private setDocuments(documents) {
        this.setState({ documents });
    }
}

export default withStyles(style)(CustomerDocumentUpload);
