import { Button, Card, CardContent, CardHeader, Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { StyledComponentProps, StyleRules, withStyles } from "@material-ui/core/styles";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { Formik } from "formik";
import i18next from "i18next";
import * as React from "react";
import { Component } from "react";
import { Trans } from "react-i18next";
import { RouteComponentProps } from "react-router";
import ConnectedLanguageSelector from "../../containers/customer/ConnectedLanguageSelector";
import ARHttp from "../../services/ARHttp";
import RegexService from "../../services/RegexService";
import ScarecrowPropertiesService from "../../services/ScarecrowPropertiesService";
import Classnames from "classnames";

const pageStyles: StyleRules = {
    form: {
        padding: "0px",
        display: "flex",
        alignItems: "left",
        justifyContent: "space-around",
        flexDirection: "column",
    },
    passwordInput: {
        textAlign: "center",
    },
    errorText: {
        color: "#83380c",
    },
    formControl: {
        marginTop: "18px",
        marginBottom: "4px",
        minWidth: "200px",
    },
    subtitle1: {
        color: "#2b7bb9",
        fontSize: "20px",
        fontWeight: 400,
    },
    text: {
        paddingTop: "16px",
        fontSize: "16px",
        paddingLeft: "40px",
    },
    body1: {
        paddingTop: "10px",
        fontSize: "12px",
        fontWeight: "normal",
        color: "rgba(0, 0, 0, 0.87)",
    },
    wordWrap: {
        wordWrap: 'break-word',
    },
};

interface Props {
    country: string;
    referrer: string;
    createAccountString: string;
    subJurisdictionCode: string;
    brand: string;
    setResetPasswordSuccess: (success: boolean) => void;
}

interface State {
    showError: boolean;
    passwordMinLength: number;
}

class ResetPassword extends Component<Props & StyledComponentProps & RouteComponentProps, State> {
    constructor(props) {
        super(props);
        this.state = {
            showError: false,
            passwordMinLength: 14,
        };
        this.validate = this.validate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    public componentDidMount() {
        window.document.title = i18next.t("customer_ui_reset_password");
        ScarecrowPropertiesService.subscribe({
            next: (function(props) {
                this.setState((state) => ({ ...state, passwordMinLength: props.passwordMinLength }));
            }).bind(this),
        });
    }

    public render() {
        const classes = this.props.classes!;
        const minLength = this.state.passwordMinLength;
        const resetError = !this.state.showError ? (
            undefined
        ) : (
            <Grid item={true} xs={12}>
                <Typography variant="body2" className={classes.errorText}>
                    <Trans>password_reset_error</Trans>
                </Typography>
            </Grid>
        );

        if ((this.props.country === "CAN" || this.props.country === "USA")) {
            return (
                <Card className={classes.form} style={{ padding: "24px", marginTop: "32px" }}>
                    <CardHeader title={<Trans i18nKey="reset_password.title" />} component="h1" />
                    <CardContent>
                        <Typography variant="body2">
                            <Trans>reset_password_subtitle</Trans>
                        </Typography>
                        {resetError}
                        <Grid>{this.showLanguageSelector(this.props.country)}</Grid>
                        <Formik initialValues={this.generateInitialValues()} validate={this.validate} onSubmit={this.onSubmit}>
                            {(formik) => (
                                <form onSubmit={formik.handleSubmit} className={classes.form}>
                                    <Grid container={true} xs={12} md={12} direction={"row"} item={true} >
                                        <Grid item={true} container={true} md={4} xs={12} direction={"column"}
                                            style={{ paddingRight: "2px" }}>
                                            <Grid className={classes.passwordInput}>
                                                <Grid item={true} md={4}>
                                                    <FormControl className={classes.formControl}>
                                                        <TextField
                                                            id="createResetPass"
                                                            label={<Trans i18nKey={"password"} />}
                                                            type="password"
                                                            value={formik.values.createResetPass}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            error={
                                                        /* istanbul ignore next */ formik.touched.createResetPass &&
                                                                !!formik.errors.createResetPass
                                                            }
                                                            helperText={
                                                        /* istanbul ignore next */ formik.touched.createResetPass &&
                                                                formik.errors.createResetPass
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item={true} md={4}>
                                                    <FormControl className={classes.formControl}>
                                                        <TextField
                                                            id="confirmResetPass"
                                                            label={<Trans i18nKey={"passwordTwo"} />}
                                                            type="password"
                                                            value={formik.values.confirmResetPass}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            error={
                                                        /* istanbul ignore next */ formik.touched.confirmResetPass &&
                                                                !!formik.errors.confirmResetPass
                                                            }
                                                            helperText={
                                                        /* istanbul ignore next */ formik.touched.confirmResetPass &&
                                                                formik.errors.confirmResetPass
                                                            }
                                                            style={{ marginBottom: "16px" }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item={true} md={4}>
                                                    <FormControl className={classes.formControl}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            type="submit"
                                                            disabled={this.shouldDisableSubmit(formik)}
                                                        >
                                                            <Trans i18nKey={"submit"} />
                                                            <ChevronRight />
                                                        </Button>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item={true} container={true} md={7} xs={12} direction={"column"}>
                                            <Grid className={classes.text} md={7}>
                                                <Typography variant="subtitle1" className={classes.subtitle1}>
                                                    <h5><strong><Trans>password_requirements</Trans></strong></h5>
                                                </Typography>
                                                <Typography variant="body1" className={Classnames(classes.body1, classes.wordWrap)}>
                                                    <ul>
                                                        <h5><li><Trans>password_minlength_error</Trans></li></h5>
                                                        <h5><li><Trans>passwords_dont_match_error</Trans></li></h5>
                                                        <h5><li><Trans>password_three_categories</Trans></li></h5>
                                                        <ul>
                                                            <h5><li><Trans>password_digits</Trans></li></h5>
                                                            <h5><li><Trans>password_uppercase</Trans></li></h5>
                                                            <h5><li><Trans>password_lowercase</Trans></li></h5>
                                                            <h5><li><Trans>password_special_characters</Trans></li></h5>
                                                        </ul>
                                                    </ul>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                        <Typography variant="body2" style={{ textAlign: "center" }}>
                            <Trans>note</Trans> : <Trans>password_prevreuse_msg</Trans>
                        </Typography>
                    </CardContent>
                </Card>
            );
        } else {
            return (
                <Card className={classes.form} style={{ padding: "24px", marginTop: "32px" }}>
                    <CardHeader title={<Trans i18nKey="reset_password.title" />} component="h1" />
                    <CardContent>
                        <Typography variant="body2">
                            <Trans>reset_password_subtitle</Trans>
                        </Typography>
                        {resetError}
                        <Grid>{this.showLanguageSelector(this.props.country)}</Grid>
                        <Formik initialValues={this.generateInitialValues()} validate={this.validate} onSubmit={this.onSubmit}>
                            {(formik) => (
                                <form onSubmit={formik.handleSubmit} className={classes.form}>
                                    <Grid className={classes.passwordInput}>
                                        <Tooltip title={i18next.t("password_tooltip", { minLength })} enterTouchDelay={1}
                                            interactive={true}>
                                            <FormControl className={classes.formControl}>
                                                <TextField
                                                    id="createResetPass"
                                                    label={<Trans>password</Trans>}
                                                    type="password"
                                                    value={formik.values.createResetPass}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        /* istanbul ignore next */ formik.touched.createResetPass &&
                                                        !!formik.errors.createResetPass
                                                    }
                                                    helperText={
                                                        /* istanbul ignore next */ formik.touched.createResetPass &&
                                                        formik.errors.createResetPass
                                                    }
                                                />
                                            </FormControl>
                                        </Tooltip>
                                        <Grid item={true} xs={12}>
                                            <FormControl className={classes.formControl}>
                                                <TextField
                                                    id="confirmResetPass"
                                                    label={<Trans>passwordTwo</Trans>}
                                                    type="password"
                                                    value={formik.values.confirmResetPass}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        /* istanbul ignore next */ formik.touched.confirmResetPass &&
                                                        !!formik.errors.confirmResetPass
                                                    }
                                                    helperText={
                                                        /* istanbul ignore next */ formik.touched.confirmResetPass &&
                                                        formik.errors.confirmResetPass
                                                    }
                                                    style={{ marginBottom: "16px" }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <FormControl className={classes.formControl}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={this.shouldDisableSubmit(formik)}
                                                >
                                                    <Trans i18nKey={"submit"} />
                                                    <ChevronRight />
                                                </Button>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                        <Typography variant="body2" style={{ textAlign: "center" }}>
                            <Trans>note</Trans> : <Trans>password_prevreuse_msg</Trans>
                        </Typography>
                    </CardContent>
                </Card>
            );
        }
    }

    private validate(values) {
        let errors = {};

        errors = { ...this.validatePassword(values.createResetPass) };

        if (values.confirmResetPass !== values.createResetPass) {
            errors = { ...errors, confirmResetPass: i18next.t("password_must_match") };
        }

        return errors;
    }

    private validatePassword(password: string) {
        let error = {};

        const minLength = Number(this.state.passwordMinLength);
        const max = 128;

        if (password === "") {
            error = { createResetPass: i18next.t("required") };
        } else if (password.length > 128) {
            error = { createResetPass: i18next.t("max_length", { max }) };
        } else if (password.toLowerCase().indexOf("password") !== -1) {
            error = { createResetPass: i18next.t("password_cannot_contain_password") };
        } else if (!RegexService.validPasswordCharsRegex.test(password)) {
            error = { createResetPass: i18next.t("password_invalid_characters_snippet") };
        } else if (!this.satisfiesPasswordCharCategories(password)) {
            error = { createResetPass: i18next.t("password_complexity_error_snippet") };
        } else if (password.length < minLength) {
            error = { createResetPass: i18next.t("min_length", { minLength }) };
        }

        return error;
    }

    private satisfiesPasswordCharCategories(password: string) {
        let count = 0;
        if (RegexService.digitsRegex.test(password)) {
            count++;
        }
        if (RegexService.lowercaseRegex.test(password)) {
            count++;
        }
        if (RegexService.uppercaseRegex.test(password)) {
            count++;
        }
        if (RegexService.specialCharsRegex.test(password)) {
            count++;
        }
        return count >= 3;
    }

    private shouldDisableSubmit(formik) {
        return !formik.isValid || !formik.dirty;
    }

    // Formik will whine if initial values are not provided.
    private generateInitialValues() {
        return { createResetPass: "", confirmResetPass: "" };
    }

    private showLanguageSelector(country: string): JSX.Element | null {
        if (country === "CAN") {
            return <ConnectedLanguageSelector />;
        } else {
            return null;
        }
    }

    private onSubmit(values) {
        this.setState({
            showError: false,
            passwordMinLength: this.state.passwordMinLength,
        });
        ARHttp({
            method: "POST",
            url: "/scwebapi/resetpw",
            body: {
                token: this.props.createAccountString,
                password: values.confirmResetPass,
                referrer: this.props.referrer,
                applicationCountry: this.props.country,
                brand: this.props.brand,
                subJurisdictionCode: this.props.subJurisdictionCode,
            },
        }).subscribe(
            (res) => {
                this.props.setResetPasswordSuccess(true);
                this.props.history.push("/login");
            },
            (error) => {
                this.setState({
                    showError: true,
                    passwordMinLength: this.state.passwordMinLength,
                });
            },
        );
    }
}

export default withStyles(pageStyles)(ResetPassword);
